// Variable overrides
$grid-bg: #CCCCCC;
$grid-text: #FFFFFF;
$grid-alt-bg:	 red;
$gray-base: #9cdc57 !default;
$primary:  #374e5d29 !default;
$body-bg: #000;
$body-color: #FFF;
$sidebar-width: 170px !default;
$theme-colors: (
  "primary": #9cdc57,
  "danger": #ff4136
);